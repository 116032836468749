import { post, get } from '@/http/http'
// 验证码获取
export const verCode = p => post('v1/userinfo/get_vercode', p)

// 登陆
export const userLogin = p => post('v1/userinfo/userlogin', p)

// 二维码
export const getQrCode = p => get('v1/userinfo/get_qrcode', p)

// 扫码登录轮询
export const QrInfo = p => post('v1/userinfo/cycle_qrcode', p)

// 二维码下载获取
export const DownQr = p => post('v1/versioninfo/app_download', p)
